.component {
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;

  @media (--viewport-md) {
    grid-template-columns: 3fr 4fr;
  }

  & > .imageContainer {
    margin-bottom: var(--size-42);

    @media (--viewport-md) {
      margin-bottom: 0;
      margin-right: var(--size-42);
    }
  }
}

.imageContainer {
  display: flex;
  justify-content: center;
  aspect-ratio: 1;
  border-radius: 50%;
  overflow: hidden;
}

.wrapper {
  & > :not(:last-child) {
    margin-bottom: var(--size-18);
  }
}

.content {
  & > :not(:last-child) {
    margin-bottom: var(--size-42);
  }
}

.links {
  & > :not(:last-child) {
    margin-right: var(--size-30);
  }
}
