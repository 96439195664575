.component,
.componentReverse {
  display: grid;
  grid-template-columns: 1fr;

  @media (--viewport-lg) {
    grid-template-columns: 3fr 7fr;
  }

  & > .factCard {
    @media (--viewport-lg) {
      max-width: 380px;
    }
  }

  & > .componentPartnerverhaal {
    max-height: 100%;
  }

  & > :first-child {
    margin-bottom: var(--size-24);

    @media (--viewport-lg) {
      margin: var(--size-96) var(--size-24) 0 0;
    }
  }

  & > :last-child {
    @media (--viewport-lg) {
      margin-bottom: var(--size-96);
    }
  }
}

.componentReverse {
  @media (--viewport-lg) {
    grid-template-columns: 7fr 3fr;
  }
}

.componentPartnerverhaal {
  overflow: hidden;
  border-radius: var(--border-radius-20);
  position: relative;
  z-index: 0;
  display: flex;
  flex-direction: column;

  @media (--viewport-md) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  & > * {
    min-width: 0;

    @media (--viewport-md) {
      grid-row: 1;
    }
  }

  & > .text {
    z-index: 1;

    @media (--viewport-md) {
      grid-column: 1;
    }
  }

  & > .image {
    z-index: 0;
    width: 80%;
    align-self: end;
    justify-self: end;

    @media (--viewport-md) {
      margin-top: var(--size-24);
      grid-column: 1 / -1;
      width: 60%;
    }
  }
}

.ondernemer {
  color: var(--color-blue-700);
}

.text {
  padding: var(--size-24);
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-end;
  gap: var(--size-24);

  @media (--viewport-md) {
    padding: var(--size-54);
  }

  @media (--viewport-lg) {
    padding: var(--size-74);
  }
}

.image {
  aspect-ratio: 1;
  clip-path: circle(100% at 100% 100%);

  & > .imageLayout {
    width: 100%;
    height: 100%;
  }
}

.blue {
  background-color: var(--color-blue-100);
}

.green {
  background-color: var(--color-green-100);
}

.orange {
  background-color: var(--color-yellow-100);
}

.pastelBlue {
  background-color: var(--color-blue-300);
}

.pastelGreen {
  background-color: var(--color-green-300);
}

.pastelOrange {
  background-color: var(--color-yellow-300);
}
