.componentBase {
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  font-size: var(--font-size-16);
  color: var(--color-blue-500);

  & > .icon {
    margin-right: var(--size-12);
    width: var(--size-24);
    height: var(--size-24);
  }
}

.componentButtonBase {
  padding: var(--size-12) var(--size-24) var(--size-12) var(--size-12);
  line-height: var(--line-height-narrow);
  color: var(--color-white);
  background-color: var(--color-blue-500);
  white-space: nowrap;
  border-radius: var(--border-radius-4);
  overflow: hidden;
}

.componentFooter {
  color: inherit;
  font-size: inherit;
}

.component {
  color: inherit;
}

.componentBack {
  line-height: var(--line-height-narrow);
}

.componentIcon {
  padding: var(--size-6);
  color: var(--color-blue-500);

  & > .icon {
    margin-right: 0;
  }
}

.componentButtonNoLabel {
  padding: var(--size-12);

  & > .icon {
    margin-right: 0;
  }
}

.componentWithIcon {
  & > .icon {
    width: var(--size-24);
  }
}

.componentButtonWithIcon,
.componentButtonWithIconGhost {
  & > .icon {
    width: var(--size-24);
  }
}

.componentButtonWithIconGhost {
  background-color: transparent;
  color: var(--color-blue-500);
  border: 1px solid var(--color-blue-500);
}

.icon {
  display: flex;
  align-items: center;
}
