.component {
  padding-block: var(--size-54);

  @media (--viewport-md) {
    padding-block: var(--size-103);
  }
}

.heading {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: var(--size-24);
  color: var(--color-white);

  & > :last-child {
    margin-top: var(--size-16);
  }
}

.grid {
  display: grid;
  grid-gap: var(--size-24);
  grid-template-columns: repeat(auto-fill, minmax(calc(320px - 10vw), 1fr));

  @media (--viewport-md) {
    grid-template-columns: repeat(2, minmax(560px, 1fr));
  }

  & > * {
    min-width: 0;
  }
}

.componentCard {
  position: relative;
  z-index: 0;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: stretch;
  overflow: hidden;
  border-radius: var(--border-radius-12);

  &::before {
    display: block;
    content: '';
    position: absolute;
    z-index: -1;
    top: 100px;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: var(--color-white);
  }

  & > .content {
    flex: 1 0 auto;
    order: 1;
  }

  & > .relativeToParent {
    position: static;
  }

  & > .image {
    width: 100%;
  }
}

.content {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: stretch;
  align-items: flex-start;
  padding: var(--size-30) var(--size-24) var(--size-24);
  position: relative;

  @media (--viewport-md) {
    padding: var(--size-30);
  }

  & > .link {
    margin-bottom: var(--size-16);
    flex: 1 0 auto;
  }

  & > .categoryLayout {
    margin-bottom: var(--size-10);
  }

  & > .subtitle {
    margin-bottom: var(--size-16);
  }

  & > .relativeToParent {
    position: static;
  }
}

.link {
  text-decoration: none;
  position: relative;

  &::after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }
}

.title {
  font-size: var(--font-size-24);
  line-height: var(--line-height-heading);
  color: var(--color-blue-700);

  @media (--viewport-md) {
    font-size: var(--font-size-32);
  }
}

.subtitle {
  font-size: var(--font-size-14);
  color: var(--color-gray-400);
}

.cardMeta {
  padding: var(--size-8) var(--size-10);
  display: flex;
  align-items: center;
  font-size: var(--font-size-14);
  background-color: var(--color-blue-50);
  color: var(--color-blue-700);
  border-radius: var(--border-radius-4);
  line-height: 1;
}

.image {
  & > * {
    width: 100%;
  }
}
