.component {
  --link-icon-size: 1;
  --background-color: var(--color-white);

  border-radius: var(--border-radius-8);
  background-color: var(--background-color);
  overflow: hidden;
  display: grid;
  grid-template-columns: 1fr;
  position: relative;

  @media (--viewport-md) {
    grid-template-columns: 1fr 1fr;
  }

  @media (--viewport-lg) {
    grid-template-columns: 2fr 1fr;
  }

  & > .imageLayout {
    height: 100%;
  }

  & > .content {
    position: static;
  }
}

.content {
  padding: var(--size-24);
  color: var(--color-blue-700);
  position: relative;
  transition: background-color var(--duration-sm) ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &:hover {
    --link-icon-size: 1.1;
    --background-color: var(--color-blue-300);
  }

  @media (--viewport-md) {
    padding: var(--size-32);
  }

  & > .link {
    position: static;
    margin-bottom: var(--size-12);
  }

  & > .chipLayout {
    margin-bottom: var(--size-32);
  }

  & > .description {
    margin-top: var(--size-24);
    margin-bottom: var(--size-3);
  }

  & > .linkIcon {
    align-self: flex-end;
    margin-top: auto;
    height: var(--size-32);
    width: var(--size-32);
  }
}

.description {
  font-size: var(--font-size-16);
  color: var(--color-gray-700);
  line-height: var(--line-height-text);
}

.link {
  position: relative;
  text-decoration: none;

  &::after {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    inset: 0;
  }
}

.title {
  font-size: var(--font-size-28);
  font-family: var(--font-family-base);
  line-height: var(--line-height-heading);
}

.linkIcon {
  background-color: var(--color-blue-700);
  color: var(--color-white);
  transition: transform var(--duration-sm) ease-in-out;
  transform: scale(var(--link-icon-size));
  border-radius: 50%;
  display: grid;
  place-items: center;
  pointer-events: none;
}
