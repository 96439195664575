.component {
  & > .aside {
    position: fixed;
    top: 50%;
    left: 0;
  }
}

.inline {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: var(--size-74);
  transform: translateX(calc(var(--size-6) * -1));

  @media (--viewport-md) {
    padding-bottom: var(--size-96);
  }

  & > :not(:last-child) {
    margin-right: var(--size-6);
  }
}

.aside {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  transform: translateX(-100%) translateY(-50%);
  transition: transform var(--duration-sm) var(--ease-out-back);

  &.isVisible {
    @media (--viewport-md) {
      transform: translateX(var(--size-6)) translateY(-50%);
    }
  }

  & > :not(:last-child) {
    margin-bottom: var(--size-6);
  }
}

.button {
  padding: var(--size-6);
  line-height: var(--line-height-narrow);
  color: var(--color-blue-500);
}
