.component {
  border-radius: var(--border-radius-12);
  overflow: hidden;
  display: flex;
  flex-direction: column;

  @media (--viewport-md) {
    flex-direction: row;
    justify-content: space-between;
  }

  &:has(.imageContainer) {
    & > .content {
      @media (--viewport-md) {
        width: 55%;
        flex-shrink: 0;
      }
    }
  }

  & > .imageContainer {
    order: -1;
    flex: 1 0;

    @media (--viewport-md) {
      order: initial;
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: var(--size-18);
  padding: var(--size-48) var(--size-24);
  color: var(--color-blue-700);

  @media (--viewport-md) {
    padding: var(--size-48);
  }

  & > .buttonLayout {
    align-self: flex-start;
  }
}

.imageContainer {
  clip-path: ellipse(100% 100% at 50% 0%);

  @media (--viewport-md) {
    clip-path: ellipse(55% 90% at 55% 50%);
  }

  & > .imageLayout {
    width: 100%;
    height: 100%;
  }
}

.blue {
  background-color: var(--color-blue-100);
}

.green {
  background-color: var(--color-green-100);
}

.orange {
  background-color: var(--color-yellow-100);
}

.pastelBlue {
  background-color: var(--color-blue-300);
}

.pastelGreen {
  background-color: var(--color-green-300);
}

.pastelOrange {
  background-color: var(--color-yellow-300);
}
