.componentBase {
  background-color: var(--color-white);
  border-bottom: 1px solid var(--color-blue-100);
  border-radius: var(--border-radius-12);
  overflow: hidden;

  & > * {
    width: 100%;
  }
}

.title {
  display: flex;
  font-weight: var(--font-weight-bold);
  line-height: var(--line-height-narrow);
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  text-align: left;

  & > .toggle {
    flex-shrink: 0;
    height: fit-content;
  }
}

.titleMd {
  padding: var(--size-24);
  font-size: var(--font-size-18);

  @media (--viewport-md) {
    padding: var(--size-42);
    font-size: var(--font-size-28);
  }

  & > .toggle {
    margin-left: var(--size-12);

    @media (--viewport-md) {
      margin-left: var(--size-24);
    }
  }
}

.titleSm {
  padding: var(--size-24);
  font-size: var(--font-size-18);

  & > .toggle {
    margin-left: var(--size-12);
  }
}

.toggle {
  display: flex;
  align-items: flex-start;
  color: var(--color-blue-500);
  transition: transform var(--duration-sm) var(--ease-in-out);

  &.isOpen {
    transform: rotate(180deg);
  }
}

.content {
  overflow: hidden;
  text-align: left;
  transition: padding var(--duration-xs);

  &:not(.isOpen) {
    padding-bottom: 0;
  }
}

.contentSm {
  --padding: var(--size-24);
}

.contentMd {
  --padding: var(--size-24);

  @media (--viewport-md) {
    --padding: var(--size-42);
  }
}

.text {
  padding: 0 var(--padding) var(--padding);
  font-size: var(--font-size-16);

  & > :not(:first-child) {
    margin-top: var(--size-24);
  }
}
