.page {
  padding-top: var(--size-96);
  padding-bottom: var(--size-96);
  background-color: var(--color-blue-100);
}

.layout {
  z-index: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 400px;

  @media (--viewport-xl) {
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-end;
  }

  & > .image {
    z-index: -1;
    max-width: 540px;

    @media (--viewport-xl) {
      position: absolute;
      left: -30%;
      max-width: 1080px;
    }
  }

  & > .contactForm {
    z-index: 1;
  }
}
