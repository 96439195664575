.component {
  line-height: var(--line-height-text);

  & > .p:not(:last-child) {
    margin-bottom: var(--size-24);
  }
}

.componentWithHeading {
  & > .withHeadingP:not(:last-child) {
    margin-bottom: var(--size-24);
  }
}

.componentMainContent {
  & > .withHeadingH2 {
    margin-bottom: var(--size-18);
  }

  & > .withHeadingH3 {
    margin-bottom: var(--size-6);
  }

  & > .withHeadingP:not(:last-child) {
    margin-bottom: var(--size-24);
  }

  & > .listUnordered {
    margin-left: var(--size-24);

    &:not(:last-child) {
      margin-bottom: var(--size-42);
    }
  }

  & > .listCheck {
    &:not(:last-child) {
      margin-bottom: var(--size-42);
    }
  }
}

.listCheck {
  display: flex;
  gap: var(--size-6);
  flex-direction: column;
}

.listItemCheckmark {
  display: flex;
  gap: var(--size-6);
}

.checkmark {
  color: var(--color-green-500);
}

.withHeadingH2,
.withHeadingH3 {
  line-height: var(--line-height-heading);
  color: var(--color-blue-700);
}

.withHeadingH2 {
  font-family: var(--font-family-heading);
  font-size: var(--font-size-32);

  @media (--viewport-md) {
    font-size: var(--font-size-52);
  }
}

.withHeadingH3 {
  @media (--viewport-md) {
    font-size: var(--font-size-24);
  }
}

.withHeadingSentenceP {
  line-height: var(--line-height-heading);
}

.withHeadingSentenceStrong {
  font-size: var(--font-size-32);

  @media (--viewport-md) {
    font-size: var(--font-size-52);
  }
}

.listUnordered {
  list-style-type: disc;
  list-style-position: outside;
}
