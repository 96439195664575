.component {
  padding-block: var(--size-96);

  & > .sentence {
    margin-right: auto;
    margin-left: auto;
    margin-bottom: var(--size-42);
    max-width: 700px;
  }

  & > .grid {
    margin-right: auto;
    margin-left: auto;
    max-width: 1024px;
  }

  & > .footnote {
    margin-top: var(--size-36);

    @media (--viewport-md) {
      margin-top: var(--size-48);
    }
  }
}

.grid {
  display: grid;
  gap: var(--size-42);
  grid-template-columns: 1fr;

  @media (--viewport-md) {
    grid-template-columns: 1fr 1fr;
  }
}

.componentVoordeel {
  display: flex;
  flex-direction: column;

  @media (--viewport-md) {
    flex-direction: row;
  }

  & > .icon {
    margin-right: var(--size-18);

    @media (--viewport-md) {
      flex: 0 0 100px;
    }
  }

  & > .title {
    margin-bottom: var(--size-8);
  }
}

.icon {
  justify-items: self-start;
  align-items: center;

  @media (--viewport-md) {
    display: grid;
    justify-items: center;
    align-items: self-start;
  }

  & > * {
    width: 85px;
  }
}

.title {
  font-size: var(--font-size-22);
  font-weight: var(--font-weight-bold);
}

.footnote {
  font-size: var(--font-size-14);
  font-style: italic;

  @media (--viewport-md) {
    text-align: center;
  }
}
