.component {
  padding-block: var(--size-48);
  position: relative;
  z-index: 0;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(3, auto);
  grid-template-areas:
    'button'
    'image'
    'text';
  gap: var(--size-48);

  @media (--viewport-lg) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: minmax(var(--size-32), auto) 1fr;
    grid-template-areas:
      'button image'
      'text   image';
    align-items: center;
    gap: unset;
  }

  & > * {
    @media (--viewport-lg) {
      height: 100%;
    }
  }

  & > .text {
    grid-area: text;
  }

  & > .image {
    grid-area: image;
  }

  & > .buttonLayout {
    grid-area: button;
    justify-self: start;
  }
}

.text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: var(--size-48);
  padding-bottom: var(--size-24);

  @media (--viewport-lg) {
    padding-block: var(--size-96);
  }

  & > .metaData {
    margin-bottom: var(--size-18);
  }
}

.metaData {
  display: flex;
  gap: var(--size-18);
  color: var(--color-blue-700);
  font-size: var(--font-size-14);
  align-items: center;
}

.type {
  display: flex;
  gap: var(--size-6);
  align-items: center;

  &:not(:last-child) {
    &::after {
      content: '•';
      left: var(--size-6);
      position: relative;
      display: block;
    }
  }

  & > * {
    height: var(--size-24);
  }
}

.heading {
  padding-bottom: var(--size-24);

  @media (--viewport-lg) {
    padding-top: var(--size-48);
  }
}

.image {
  aspect-ratio: 1;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  & > .imageLayout {
    width: 100%;
    height: 100%;
  }
}
