.componentBase {
  display: flex;
  align-items: center;
}

.component {
  background-color: #0000ff;
  color: #fff;
  padding: 12px 18px;
  font-size: 16px;
}

.componentLink {
  color: #0000ff;
  padding: 12px 18px;
  font-size: 16px;
  border: 1px solid #0000ff;
}
