.componentBase {
  font-family: var(--font-family-heading);
  font-weight: var(--font-weight-light);
  color: var(--color-blue-700);
  text-align: center;
}

.componentMd {
  font-size: var(--font-size-28);

  @media (--viewport-md) {
    font-size: var(--font-size-48);
  }
}
