.component {
  position: relative;
  z-index: 0;
  display: grid;
  aspect-ratio: 16 / 9;
  clip-path: inset(0 round var(--border-radius-20));
  overflow: hidden;
  color: var(--color-white);

  & > * {
    min-width: 0;
    grid-area: 1 / 1;
  }

  & > .video,
  & > .poster {
    width: 100% !important; /* Prevent override by react-player */
    height: 100% !important; /* Prevent override by react-player */
  }

  & > .poster {
    z-index: 1;
  }
}

.poster {
  position: relative;
  z-index: 0;
  display: flex;
  align-items: flex-end;

  & > .posterImage {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.posterImage {
  & > * {
    width: 100%;
    height: 100%;
  }
}

.componentPlayButton {
  position: relative;
  display: flex;
  align-items: center;
  padding: var(--size-30);

  & > .meta {
    margin-left: var(--size-30);
  }

  & > .button {
    width: var(--size-60);
    height: var(--size-60);
  }
}

.title {
  font-weight: var(--font-weight-semi-bold);
}

.button {
  position: relative;
  padding: var(--size-18);
  background-color: var(--color-blue-500);
  border-radius: 50%;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.duration {
  font-size: var(--font-size-14);
  line-height: var(--font-size-14);
}
