.component {
  padding-block: var(--size-48);

  @media (--viewport-md) {
    padding-block: var(--size-74);
  }
}

.layout {
  display: grid;
  grid-template-columns: 1fr;
  justify-content: flex-end;

  @media (--viewport-md) {
    grid-template-columns: 2fr 1fr;
  }

  & > .text {
    margin-bottom: var(--size-74);

    @media (--viewport-md) {
      margin-bottom: var(--size-48);
    }
  }
}

.text {
  & > .intro {
    margin-top: var(--size-48);

    @media (--viewport-md) {
      margin-top: var(--size-74);
      margin-right: var(--size-134);
    }
  }
}

.intro {
  font-size: var(--font-size-22);
  color: var(--color-blue-700);
  line-height: var(--line-height-intro);
}

.image {
  aspect-ratio: 1;
  border-radius: 50%;
  overflow: hidden;

  & > .imageLayout {
    width: 100%;
    height: 100%;
  }
}
