.page {
  padding-block: var(--size-96);
}

.layout {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  text-align: center;

  & > :not(:last-child) {
    margin-bottom: var(--size-24);
  }
}
