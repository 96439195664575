.component {
  display: flex;
  flex-direction: column;
  gap: var(--size-24);

  @media (--viewport-md) {
    gap: var(--size-48);
    flex-direction: row;
    align-items: flex-start;
  }

  &.reverse {
    @media (--viewport-md) {
      flex-direction: row-reverse;
    }
  }

  & > .content {
    @media (--viewport-md) {
      flex-basis: 60%;
    }
  }

  & > .image {
    @media (--viewport-md) {
      flex-basis: 40%;
    }
  }
}

.image {
  border-radius: var(--border-radius-20);
  overflow: hidden;

  & > .imageLayout {
    width: 100%;
    height: 100%;
  }
}
