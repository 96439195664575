.heading {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: var(--size-24);

  & > :last-child {
    margin-top: var(--size-12);
  }
}

.grid {
  padding-bottom: var(--size-48);
  display: grid;
  gap: var(--size-24);
  grid-template-columns: repeat(auto-fill, minmax(calc(320px - 10vw), 1fr));

  @media (--viewport-md) {
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  }

  & > * {
    min-width: 0;
  }
}
