.page {
  & > .sectionIntro {
    margin-top: var(--size-30);
    margin-bottom: var(--size-30);

    @media (--viewport-md) {
      margin-top: var(--size-42);
      margin-bottom: var(--size-42);
    }
  }

  & > .sectionHero {
    overflow: hidden;
  }
}

.sectionPadding {
  padding-block: var(--size-54);

  @media (--viewport-md) {
    padding-block: var(--size-74);
  }
}

.sectionIntro {
  font-size: var(--font-size-20);
  line-height: var(--line-height-intro);
  color: var(--color-blue-700);

  @media (--viewport-md) {
    font-size: var(--font-size-28);
  }
}

.image {
  display: flex;
  justify-content: center;
}
