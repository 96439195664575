.component {
  border-radius: var(--size-12);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: var(--size-30);
  gap: var(--size-16);

  & > .imageContainer {
    margin-top: auto;
    width: clamp(150px, 100%, 30%);
    align-self: flex-end;
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: var(--size-18);
}

.imageContainer {
  border-radius: 50%;
  overflow: hidden;
}

.blue {
  background-color: var(--color-blue-100);
}

.green {
  background-color: var(--color-green-100);
}

.orange {
  background-color: var(--color-yellow-100);
}

.pastelBlue {
  background-color: var(--color-blue-300);
}

.pastelGreen {
  background-color: var(--color-green-300);
}

.pastelOrange {
  background-color: var(--color-yellow-300);
}
