:root {
  --border-radius-3: 3px;
  --border-radius-4: 4px;
  --border-radius-6: 6px;
  --border-radius-8: 8px;
  --border-radius-10: 10px;
  --border-radius-12: 12px;
  --border-radius-20: 20px;
  --border-radius-50: 50px;
}
