.component {
  background-color: var(--color-blue-700);
}

.content {
  padding: var(--size-24);
  font-size: var(--font-size-14);
  color: var(--color-white);
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;

  @media (--viewport-md) {
    padding: var(--size-48);
  }
}

.logo {
  display: block;
}

.links {
  padding-top: var(--size-30);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  color: var(--color-white);

  @media (--viewport-md) {
    padding-top: var(--size-48);
  }

  & > * {
    margin: 0 var(--size-12);
  }
}
