.component {
  & > .header {
    margin-bottom: var(--size-54);
  }
}

.header {
  text-align: center;
}

.images {
  display: flex;
  flex-direction: column;
  gap: var(--size-24);

  @media (--viewport-md) {
    gap: var(--size-48);
    flex-direction: row;
  }

  & > * {
    @media (--viewport-md) {
      flex-basis: 50%;
    }
  }
}

.image {
  border-radius: var(--border-radius-20);
  overflow: hidden;

  & > .imageLayout {
    width: 100%;
  }
}
