.componentBase {
  padding: var(--size-30);
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  overflow: hidden;
  border-radius: var(--border-radius-20);

  & > .content {
    flex-grow: 1;

    @media (--viewport-md) {
      max-width: calc(100% - 100px);
    }
  }
}

.component {
  @media (--viewport-md) {
    padding: var(--size-30);
    flex-direction: row;
  }
}

.componentWithIllustrationVerticalBase {
  & > .illustration {
    align-self: center;
  }
}

.componentOrange {
  background-color: var(--color-yellow-100);
}

.componentWithIllustrationBlue,
.componentWithIllustrationVerticalBlue {
  background-color: var(--color-blue-100);
}

.componentWithIllustrationGreen,
.componentWithIllustrationVerticalGreen {
  background-color: var(--color-green-100);
}

.content {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;

  & > .subtitle {
    margin-top: var(--size-12);
    margin-bottom: var(--size-18);
  }
}

.cijfer,
.cijferDescription,
.subtitle {
  font-family: var(--font-family-heading);
  line-height: var(--line-height-heading);
  color: var(--color-blue-700);
}

.cijfer {
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-72);
}

.cijferDescription {
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-42);
}

.subtitle {
  font-size: var(--font-size-24);
}
