.component {
  display: flex;
  flex-direction: column;
  gap: var(--size-24);

  @media (--viewport-md) {
    gap: var(--size-48);
    flex-direction: row;
    align-items: center;
  }

  &.reverse {
    @media (--viewport-md) {
      flex-direction: row-reverse;
    }
  }

  & > * {
    @media (--viewport-md) {
      flex-basis: 50%;
    }
  }
}

.image {
  border-radius: 50%;
  overflow: hidden;

  & > .imageLayout {
    aspect-ratio: 1;
    width: 100%;
  }
}
