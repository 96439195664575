.component {
  display: flex;
  flex-direction: column;
  gap: var(--size-24);

  @media (--viewport-md) {
    gap: var(--size-48);
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }

  & > .content {
    @media (--viewport-md) {
      max-width: var(--size-container-max-width-sm);
      flex-basis: 80%;
    }
  }

  & > .image {
    @media (--viewport-md) {
      flex-basis: 20%;
    }
  }
}

.content {
  & > :not(:last-child) {
    margin-bottom: var(--size-30);
  }
}

.highlighted {
  padding: var(--size-24);
  background-color: var(--color-blue-700);
  color: var(--color-white);
  border-radius: var(--border-radius-20);
}

.image {
  border-radius: var(--border-radius-20);
  overflow: hidden;

  & > .imageLayout {
    width: 100%;
    height: 100%;
  }
}
