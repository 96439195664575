.component {
  & > :not(:last-child):not(:first-child) {
    margin-bottom: var(--size-18);
  }

  & > .roadmapTitle {
    margin-bottom: var(--size-74);
  }
}

.roadmapTitle {
  text-align: center;
}

.periode {
  display: grid;
  grid-template-columns: 1fr;

  & > .title {
    margin-bottom: var(--size-12);
    margin-top: var(--size-18);

    @media (--viewport-md) {
      margin-bottom: var(--size-18);
    }
  }

  @media (--viewport-md) {
    grid-template-columns: 1fr 2fr;
  }
}

.features {
  display: flex;
  flex-direction: column;

  & > .feature:not(:last-child) {
    margin-bottom: var(--size-12);

    @media (--viewport-md) {
      margin-bottom: var(--size-18);
    }
  }
}
