.page {
  padding-bottom: var(--size-42);

  @media (--viewport-md) {
    padding-bottom: var(--size-54);
  }

  & > .sectionIntro {
    margin-top: var(--size-30);
    margin-bottom: var(--size-30);

    @media (--viewport-md) {
      margin-top: var(--size-42);
      margin-bottom: var(--size-42);
    }
  }
}

.sectionIntro {
  font-size: var(--font-size-20);
  line-height: var(--line-height-intro);
  color: var(--color-brand);

  @media (--viewport-md) {
    font-size: var(--font-size-28);
  }
}
