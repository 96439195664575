.component {
  padding: var(--size-16) var(--size-16) var(--size-32);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--size-24);
  background-color: var(--color-white);
  border-radius: var(--border-radius-20);
  overflow: hidden;
  position: relative;

  @media (--viewport-lg) {
    gap: var(--size-32);
    flex-direction: row;
  }

  &:not(:has(img)) {
    padding: var(--size-16);
  }

  & > .image {
    width: 100%;

    @media (--viewport-lg) {
      flex-shrink: 0;
      width: 140px;
    }
  }
}

.image {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: var(--border-radius-10);
  aspect-ratio: 5 / 3;

  @media (--viewport-lg) {
    aspect-ratio: 1;
  }

  & > * {
    width: 100%;
    height: 100%;
  }
}

.content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--size-18);

  & > .title {
    height: 100%;
  }

  & > .category {
    order: -1;

    @media (--viewport-lg) {
      margin-top: var(--size-12);
    }
  }
}

.category {
  display: flex;
  align-items: center;
  color: var(--color-gray-300);
  gap: var(--size-6);

  & > * {
    height: var(--size-24);
  }
}

.title {
  font-size: var(--font-size-24);
  line-height: var(--line-height-card-title);
  color: var(--color-blue-700);

  @media (--viewport-md) {
    font-size: var(--font-size-32);
  }
}
