.component {
  display: flex;
  flex-direction: column;

  @media (--viewport-md) {
    flex-direction: row;
    position: relative;
    justify-content: flex-end;
    align-items: center;
    z-index: 0;
    min-height: 430px !important;
  }

  & > .image {
    max-width: 100%;
    align-self: center;
    width: 1020px;

    @media (--viewport-md) {
      position: absolute;
      left: -20%;
      max-width: 85%;
      z-index: -1;
    }
  }

  & > .text {
    @media (--viewport-md) {
      max-width: 400px;
      z-index: 0;
    }

    @media (--viewport-lg) {
      max-width: 550px;
    }
  }
}

.text {
  padding: var(--size-30);
  display: flex;
  flex-direction: column;

  & > :not(:first-child) {
    margin-top: var(--size-18);
  }

  & > .title {
    align-self: center;
  }
}

.title {
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-28);
  line-height: var(--line-height-narrow);
  color: var(--color-blue-700);

  @media (--viewport-md) {
    font-size: var(--font-size-52);
  }
}

.componentUsps {
  padding: 0;
  list-style: none;

  & > * {
    margin-top: var(--size-12);
  }
}

.componentUsp {
  display: flex;
  font-size: var(--font-size-14);

  & > .icon {
    margin-right: var(--size-12);
    flex: 0 0 var(--size-24);
  }
}

.icon {
  color: var(--color-green-500);
  transform: translateY(-2px);
}
