.component {
  background-color: var(--color-white);
  position: sticky;
  top: 0;
}

.layout {
  display: flex;
  justify-content: space-between;
  padding-block: var(--size-12);

  @media (--viewport-lg) {
    padding: 0;
  }

  & > .navItems {
    min-height: 84px;
  }

  & > .scrollButton {
    align-self: center;
  }
}

.navItems {
  display: none;
  list-style: none;
  color: var(--color-blue-700);
  padding-left: 0;

  & > :not(:last-child) {
    margin-right: var(--size-24);
  }

  & > * {
    align-self: stretch;
  }

  @media (--viewport-lg) {
    display: flex;
  }
}

.selectWrapper {
  display: flex;
  align-items: center;
  position: relative;
  padding-right: var(--size-12);

  & > .selectIcon {
    position: absolute;
    right: var(--size-12);
  }

  & > .sectionSelect {
    max-width: 160px;

    @media (--viewport-sm) {
      max-width: 300px;
    }
  }
}

.sectionSelect {
  text-overflow: ellipsis;
  display: flex;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  color: var(--color-blue-700);
  border: none;
  padding: var(--size-12);
  padding-right: var(--size-24);
  background-color: var(--color-white);

  &::-ms-expand {
    display: none;
  }

  @media (--viewport-lg) {
    display: none;
  }
}

.selectIcon {
  display: flex;
  color: var(--color-blue-700);
  pointer-events: none;
}

.navItem {
  display: flex;
  align-items: center;
  border-bottom: var(--size-6) solid transparent;
  padding-left: var(--size-18);
  padding-right: var(--size-18);
  padding-top: var(--size-6);

  &.isActive,
  &:hover {
    border-bottom: var(--size-6) solid var(--color-blue-500);
  }
}

.navItemLink {
  color: var(--color-blue-700);
  text-decoration: none;
}
