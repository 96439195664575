.component {
  display: flex;
  flex-direction: column;
  border-radius: var(--border-radius-12);
  overflow: hidden;
  background-color: var(--color-white);

  & > .image {
    width: 100%;
    align-self: center;
  }

  & > .text {
    width: 100%;
    height: 100%;
  }
}

.text {
  padding: var(--size-30);
  display: flex;
  flex-direction: column;

  & > :not(:first-child) {
    margin-top: var(--size-18);
  }

  & > .list {
    flex-grow: 1;
  }

  & > .title {
    align-self: center;
  }
}

.title {
  font-weight: var(--font-weight-bold);
  line-height: var(--line-height-narrow);
  color: var(--color-blue-700);

  @media (--viewport-md) {
    font-size: var(--font-size-24);
  }
}

.list {
  padding: 0;
  list-style: none;

  & > .listItem {
    margin-top: var(--size-12);
  }
}

.listItem {
  display: flex;
  font-size: var(--font-size-14);

  & > .icon {
    margin-right: var(--size-12);
    flex: 0 0 var(--size-24);
  }
}

.icon {
  color: var(--color-green-500);
  transform: translateY(-2px);
}
