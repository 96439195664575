.component {
  display: flex;
  flex-direction: column;
  gap: var(--size-10);
}

.componentTemporaryCopyComponent {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  column-gap: var(--size-10);
  font-size: var(--font-size-16);
}
