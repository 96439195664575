.component {
  padding-top: var(--size-24);
  padding-bottom: var(--size-54);
  gap: var(--size-18);
  display: grid;
  grid-template-areas:
    'selectedFiltersHeader'
    'filters'
    'agencies'
    'pagination';

  @media (--viewport-lg) {
    grid-template-columns: 1fr 2fr;
    grid-template-areas:
      'selectedFiltersHeader selectedFiltersHeader'
      'filters agencies'
      '....    pagination';
  }

  & > * {
    min-width: 0;
  }

  & > .selectedFiltersHeader {
    grid-area: selectedFiltersHeader;
  }

  & > .filtersLayout {
    grid-area: filters;
  }

  & > .agenciesLayout {
    grid-area: agencies;
  }

  & > .loaderLayout {
    margin: 6vmin auto 0;
    grid-area: agencies;
  }

  & > .paginationLayout {
    grid-area: pagination;
  }
}

.selectedFiltersHeader {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: var(--size-14);
  color: var(--color-blue-700);

  & > .selectedFiltersLayout {
    min-height: 43px;
  }
}
