.component {
  & > .header {
    margin-bottom: var(--size-54);
  }
}

.header {
  text-align: center;
}

.imageContainer {
  border-radius: var(--border-radius-20);
  overflow: hidden;
}
