.page {
  & > .sectionIntro {
    margin-top: var(--size-30);
    margin-bottom: var(--size-30);

    @media (--viewport-md) {
      margin-top: var(--size-42);
      margin-bottom: var(--size-42);
    }
  }

  & > .sectionHero {
    overflow: hidden;
  }
}

.sectionIntro {
  font-size: var(--font-size-20);
  line-height: var(--line-height-intro);
  color: var(--color-blue-700);

  @media (--viewport-md) {
    font-size: var(--font-size-28);
  }
}

.sectionGerelateerdeArtikelen {
  position: relative;
  z-index: 0;

  &::before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 323px;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: var(--color-blue-700);
  }
}
