.page {
  position: relative;
  z-index: 0;

  & > .sectionGridCards {
    position: relative;
    z-index: 1;
    max-width: 100%;

    @media (--viewport-lg) {
      margin-top: -225px;
      min-height: 225px;
    }
  }
}

.sectionHero {
  overflow: hidden;
}

.sectionGridCards {
  padding-block: var(--size-54);

  @media (--viewport-lg) {
    padding-top: 0;
    padding-bottom: var(--size-74);
  }
}

.sectionPadding {
  padding-block: var(--size-54);

  @media (--viewport-md) {
    padding-block: var(--size-74);
  }
}
