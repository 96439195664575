.component {
  margin: 6vmin auto 0;

  & > .content {
    height: fit-content;
    margin-top: calc(-1 * var(--size-16));
    max-width: 50ch;
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: var(--size-12);
  background-color: var(--color-blue-100);
  color: var(--color-blue-700);
  padding: var(--size-32);

  & > .buttonLayout {
    width: 100%;
  }
}
