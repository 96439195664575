.component {
  --slide-height: 550px;
  --heading-top: var(--size-54);

  position: relative;

  & > .heading {
    top: var(--heading-top);
  }

  & > .slides {
    margin-top: calc(var(--size-30) - var(--slide-height));
  }
}

.heading {
  position: sticky;
  margin-bottom: calc(var(--size-30) + var(--slide-height));
}

.slides {
  & > .slide {
    top: calc(var(--heading-height, 0px) + var(--heading-top) + var(--size-30));
  }
}

.slide {
  position: sticky;
  contain: layout;

  & > * {
    height: var(--slide-height);
    overflow: hidden;
    max-height: calc(100vh - var(--heading-top) - 2 * var(--size-30));
  }
}

.componentContent {
  position: relative;
  z-index: 0;
  overflow: hidden;
  border-radius: var(--border-radius-20);
  max-height: 100%;

  & > .text {
    width: clamp(150px, 80vw, 600px);
  }

  & > .image {
    position: absolute;
    top: var(--size-24);
    right: -25%;
    z-index: -1;
    width: clamp(400px, 80vw, 800px);
  }
}

.ondernemer {
  color: var(--color-blue-700);
}

.text {
  padding: var(--size-30);
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-end;
  gap: var(--size-18);

  @media (--viewport-md) {
    padding: var(--size-74);
  }
}

.image {
  aspect-ratio: 1;
  border-radius: 50%;
  overflow: hidden;

  & > .imageLayout {
    width: 100%;
    height: 100%;
  }
}

.blue {
  background-color: var(--color-blue-100);
}

.green {
  background-color: var(--color-green-100);
}

.orange {
  background-color: var(--color-yellow-100);
}

.pastelBlue {
  background-color: var(--color-blue-300);
}

.pastelGreen {
  background-color: var(--color-green-300);
}

.pastelOrange {
  background-color: var(--color-yellow-300);
}
