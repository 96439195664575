.component_root {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 0;

  &::before {
    content: '';
    background-color: rgba(10, 10, 10, 0.6);
    height: 100%;
    width: 100%;
    display: block;
    position: fixed;
    z-index: -1;
  }

  & > .content {
    max-height: 95%;
    width: 700px;
    max-width: 90%;
  }
}

.content {
  background: #fff;
  padding: 18px;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;

  & > .error {
    margin-top: 18px;
  }
}

.error {
  color: red;
  font-size: 14px;
}

.header {
  padding-bottom: 12px;

  & > .logo {
    width: 82px;
    height: 40px;
    margin-bottom: 6px;

    @media screen and (min-width: 1024px) {
      height: 35px;
      margin-bottom: 12px;
    }
  }
}

.title {
  font-family: 'Produkt', Georgia, Times, 'Times New Roman', serif, Helvetica;
  color: #03031a;
  font-size: 28px;
}

.logo {
  color: #0000a4;
}

.body {
  padding-bottom: 18px;
  font-size: 14px;
  line-height: calc(26 / 16);
  color: #0a0a0a;

  & > :not(:last-child) {
    margin-bottom: 18px;
  }

  & a {
    color: #0000ff;

    &:hover {
      text-decoration: underline;
    }
  }
}

.footer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-top: 18px;
  gap: 12px;
  border-top: 1px solid #aaa;

  @media screen and (min-width: 1024px) {
    flex-direction: row;
    justify-content: flex-start;
  }
}

.permissions {
  padding-bottom: 20px;

  & > :not(:last-child) {
    margin-bottom: 10px;
  }
}

.permissionCategoryComponent {
  display: flex;
  gap: 20px;
}

.permissionBody {
  font-size: 14px;
}

.permissionTitle {
  font-family: 'Graphik', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 16px;
  color: #03031a;
}

.label {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 30px;
  flex-shrink: 0;

  & > .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.label .checkbox {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  background-color: #cccccc;
  border-radius: 30px;

  &:before {
    position: absolute;
    content: "";
    height: 22px;
    width: 22px;
    left: 4px;
    bottom: 4px;
    background-color: #fff;
    border-radius: 50%;
  }
}

.checkbox:checked+.slider {
  background-color: #0000ff;
}

.checkbox:checked+.slider:before {
  transform: translateX(22px);
}
