.page {
  & > * {
    max-width: 100vw;
  }

  & > .hero {
    margin-bottom: var(--size-54);

    @media (--viewport-md) {
      margin-bottom: var(--size-74);
    }
  }
}

.sectionPadding {
  padding-block: var(--size-54);

  @media (--viewport-md) {
    padding-block: var(--size-74);
  }
}
