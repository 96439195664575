.component {
  display: grid;
  grid-template-columns: 78px 1fr;
  gap: var(--size-30) var(--size-18);

  & > .text {
    grid-area: 1 / 1 / 2 / 3;
  }

  & > .naamFunctie {
    grid-area: 2 / 2 / 3 / 3;
  }

  & > .image {
    width: 78px;
    height: 78px;
  }
}

.text {
  font-family: var(--font-family-heading);
  font-size: var(--font-size-42);
  line-height: var(--line-height-heading);
  color: var(--color-blue-700);

  @media (--viewport-md) {
    font-size: var(--font-size-72);
  }
}

.image {
  overflow: hidden;
  border-radius: 50%;
}

.naamFunctie {
  display: grid;
  place-content: center left;
}

.naam {
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-24);
  line-height: var(--line-height-narrow);
  color: var(--color-blue-700);
}

.functie {
  font-size: var(--font-size-16);
}
