.component {
  display: flex;
  flex-wrap: wrap;
  gap: var(--size-12);
}

.componentButtonLabel {
  display: flex;
  align-items: center;
  gap: var(--size-8);
  color: var(--color-white);
  background-color: var(--color-blue-700);
  padding: var(--size-12) var(--size-18);
  border-radius: var(--border-radius-50);
  font-size: var(--font-size-14);

  & > .iconLayout {
    flex-shrink: 0;
    width: 12px;
  }
}
