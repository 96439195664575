.component {
  max-width: 780px !important;

  & > :first-child {
    margin-bottom: var(--size-24);
  }

  & > .erorrBanner {
    margin-top: var(--size-30);
  }
}

.contactFormGrid {
  display: grid;
  grid-template-columns: 1fr;
  column-gap: var(--size-12);
  row-gap: var(--size-12);

  @media (--viewport-md) {
    grid-template-columns: 1fr 1fr;
  }

  & > .fullwidth,
  & > .formFooter {
    grid-column: 1 / -1;
  }
}

.doubleField {
  & > .inputLabel {
    margin-bottom: var(--size-3);
  }
}

.doubleFieldLayout {
  display: grid;
  grid-template-columns: min(100px) 1fr;
}

.componentFormFieldSelect,
.componentLanguageSelect {
  position: relative;

  & > .select {
    width: 100%;
    height: var(--size-48);
  }

  & > .selectIcon {
    height: 100%;
    position: absolute;
    top: 0;
    right: var(--size-12);
  }
}

.componentLanguageSelect {
  display: flex;

  & > .selectFlagIcon {
    height: 100%;
    position: absolute;
    left: var(--size-12);
  }
}

.select {
  -moz-appearance: none;
  -webkit-appearance: none;
  padding-right: var(--size-12);
  padding-left: var(--size-12);

  &::-ms-expand {
    display: none;
  }
}

.selectIcon,
.selectFlagIcon {
  display: flex;
  align-items: center;
  pointer-events: none;
  color: var(--color-blue-700);
}

.input,
.select,
.textarea {
  font-size: var(--font-size-16);
  font-weight: var(--font-weight-base);
  border: none;
  padding: 0 var(--size-18);
}

.input,
.select {
  background-color: var(--color-white);
}

.inputLabel {
  display: block;
  font-weight: var(--font-weight-semi-bold);
}

.textarea {
  resize: none;
  padding: var(--size-18);
  font-family: var(--font-family-base);
}

.inputWrapper {
  & > .input {
    width: 100%;
    height: var(--size-48);
  }

  & > .textarea {
    width: 100%;
    min-height: 200px;
  }
}

.selectLanguage {
  background-color: var(--color-background);
  text-align: center;
}

.componentFormFieldBase {
  & > .inputError {
    margin-top: var(--size-12);
  }

  & > .inputLabel {
    margin-bottom: var(--size-3);
  }
}

.inputError {
  background-color: var(--color-red-400);
  display: flex;
  align-items: center;
  padding-left: var(--size-12);
  padding-right: var(--size-12);
  color: var(--color-white);
  font-size: var(--font-size-14);
  line-height: var(--line-height-narrow);
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: calc(var(--size-6) * -1);
    left: var(--size-12);
    border-left: var(--size-6) solid transparent;
    border-right: var(--size-6) solid transparent;
    border-bottom: var(--size-6) solid var(--color-red-400);
  }
}

.error {
  border: 2px solid var(--color-red-400);
}

.formFooter {
  display: flex;
  justify-content: center;
}

.componentSubmitButton {
  padding: var(--size-12) var(--size-24) var(--size-12) var(--size-12);
  line-height: var(--line-height-narrow);
  color: var(--color-white);
  background-color: var(--color-blue-500);
  white-space: nowrap;
  display: flex;
  align-items: center;

  & > :nth-child(2) {
    margin-left: var(--size-6);
  }

  &:disabled {
    background-color: transparent;
  }
}

.isLoading {
  cursor: wait;
}

.erorrBanner {
  background-color: var(--color-red-400);
  color: var(--color-white);
  font-size: var(--font-size-18);
  padding: var(--size-18);
}
