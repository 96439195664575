.component {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  & > .heading {
    margin-bottom: var(--size-24);
  }
}

.heading {
  display: flex;
  flex-direction: column;
}

.cards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  gap: var(--size-24);

  & > * {
    flex: 1 0 280px;
    max-width: 400px;
  }
}
