.cards {
  & > * {
    margin-top: var(--size-18);
  }

  @media (--viewport-md) {
    display: grid;
    grid-template-columns: repeat(3, minmax(200px, 1fr));
    gap: 0 var(--size-18);
    grid-template-areas:
      'fact1 fact1 fact2'
      'fact3 fact4 fact4';
  }

  & > :nth-child(1) {
    @media (--viewport-md) {
      grid-area: fact1;
    }
  }

  & > :nth-child(2) {
    @media (--viewport-md) {
      grid-area: fact2;
    }
  }

  & > :nth-child(3) {
    @media (--viewport-md) {
      grid-area: fact3;
    }
  }

  & > :nth-child(4) {
    @media (--viewport-md) {
      grid-area: fact4;
    }
  }
}
