.componentGerelateerdeArtikelen {
  position: relative;
  z-index: 0;

  &::before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 323px;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: var(--color-blue-700);
  }
}

.blue {
  background-color: var(--color-blue-100);
}

.darkBlue {
  background-color: var(--color-blue-300);
}

.green {
  background-color: var(--color-green-100);
}

.orange {
  background-color: var(--color-yellow-100);
}

.pastelBlue {
  background-color: var(--color-blue-300);
}

.pastelGreen {
  background-color: var(--color-green-300);
}

.pastelOrange {
  background-color: var(--color-yellow-300);
}
