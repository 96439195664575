.component {
  display: flex;
  flex-direction: column;
  gap: var(--size-24);
}

.results {
  display: flex;
  flex-direction: column;
  gap: var(--size-24);
}

