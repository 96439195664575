.component {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: var(--size-30);
  background-color: var(--color-blue-100);
  border-radius: var(--border-radius-12);
  overflow: hidden;

  @media (--viewport-md) {
    padding: var(--size-42);
  }

  & > .image,
  & > .naam,
  & > .functie {
    align-self: center;
  }

  & > .image {
    margin-bottom: var(--size-24);
    width: 175px;
    height: 175px;
  }

  & > .functie {
    margin-bottom: var(--size-6);
  }

  & > .description {
    flex-grow: 1;
    margin-bottom: var(--size-24);
  }
}

.image {
  overflow: hidden;
  border-radius: 50%;
  background-color: var(--color-background);
}

.naam {
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-28);
  line-height: var(--line-height-narrow);
  color: var(--color-blue-700);
}

.functie {
  color: var(--color-blue-700);
}

.description {
  font-size: var(--font-size-16);
}

.contact {
  display: flex;
  flex-direction: column;

  & > :not(:last-child) {
    margin-bottom: var(--size-6);
  }
}
