
.component {
  padding: var(--size-32);
  background-color: var(--color-white);
  overflow: hidden;
  border-radius: var(--border-radius-12);
  display: grid;
  gap: var(--size-32);
  grid-template-areas:
    'image   icon'
    'content content';

  @media (--viewport-lg) {
    grid-template-columns: auto 1fr;
    grid-template-areas: 'image content icon';
  }

  & > * {
    min-width: 0;
  }

  & > .iconLayout {
    grid-area: icon;
    width: 55px;
    justify-self: end;
  }

  & > .content {
    grid-area: content;
  }

  & > .image {
    width: 80px;
    height: 80px;
    grid-area: image;

    @media (--viewport-lg) {
      width: 160px;
      height: 160px;
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: var(--size-10);

  & > .actions {
    margin-top: var(--size-12);
  }
}

.text {
  font-size: var(--font-size-18);
}

.image {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--color-gray-100);
  padding: var(--size-8);
}

.actions {
  display: flex;
  flex-wrap: wrap;
  gap: var(--size-18);
}
