.component {
  display: flex;
  flex-direction: column-reverse;
  gap: var(--size-32);
  padding: var(--size-48);
  background-color: var(--color-blue-100);
  overflow: hidden;
  border-radius: var(--border-radius-12);

  @media (--viewport-md) {
    flex-direction: row-reverse;
    align-items: center;
  }

  & > .content {
    width: 100%;
  }

  & > .iconLayout {
    flex-shrink: 0;
    max-width: 144px;
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: var(--size-12);
  align-items: flex-start;
}
