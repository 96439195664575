.componentBase {
  font-family: var(--font-family-heading);
  line-height: var(--line-height-heading);
  color: var(--color-blue-700);
  text-wrap: pretty;
}

.subtitle {
  font-weight: var(--font-weight-light);
}

.componentSm,
.componentMd {
  & > .subtitle {
    margin-top: var(--size-6);
  }
}

.componentLg,
.componentXl {
  & > .subtitle {
    margin-top: var(--size-18);
  }
}

.componentHeaderTitleSm {
  font-size: var(--font-size-28);

  @media (--viewport-md) {
    font-size: var(--font-size-32);
  }
}

.componentHeaderTitleMd {
  font-size: var(--font-size-32);

  @media (--viewport-md) {
    font-size: var(--font-size-52);
  }
}

.componentHeaderTitleLg {
  font-size: var(--font-size-42);

  @media (--viewport-md) {
    font-size: var(--font-size-72);
  }
}

.componentHeaderTitleXl {
  font-size: var(--font-size-52);

  @media (--viewport-md) {
    font-size: var(--font-size-92);
  }
}

.componentHeaderSubtitleSm {
  font-size: var(--font-size-18);

  @media (--viewport-md) {
    font-size: var(--font-size-22);
  }
}

.componentHeaderSubtitleMd {
  font-size: var(--font-size-24);

  @media (--viewport-md) {
    font-size: var(--font-size-32);
  }
}

.componentHeaderSubtitleLg {
  font-size: var(--font-size-32);
}

.componentHeaderSubtitleXl {
  font-size: var(--font-size-32);

  @media (--viewport-md) {
    font-size: var(--font-size-48);
  }
}
