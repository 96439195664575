.page {
  & > .sectionMargin {
    margin-top: var(--size-54);
    margin-bottom: var(--size-54);

    @media (--viewport-md) {
      margin-top: var(--size-74);
      margin-bottom: var(--size-74);
    }
  }

  & > .sectionHero {
    margin-top: calc(var(--size-74) * 2.5);
    margin-bottom: var(--size-74);

    @media (--viewport-md) {
      margin-top: calc(var(--size-96) * 3);
      margin-bottom: var(--size-96);
    }
  }

  &:last-child {
    padding-bottom: var(--size-54);

    @media (--vieport-md) {
      padding-bottom: var(--size-74);
    }
  }
}
