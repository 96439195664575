.component {
  display: inline-block;
  position: relative;
  width: 40px !important;
  height: 40px !important;

  & > * {
    position: absolute;
    width: 32px;
    height: 32px;
    margin: 4px;
  }

  & > :nth-child(1) {
    animation-delay: -0.45s;
  }

  & > :nth-child(2) {
    animation-delay: -0.3s;
  }

  & > :nth-child(3) {
    animation-delay: -0.15s;
  }
}

.part {
  box-sizing: border-box;
  display: block;
  border: 4px solid var(--color-blue-500);
  border-radius: 50%;
  animation: component 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: var(--color-blue-500) transparent transparent transparent;
}

@keyframes component {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
