.page {
  position: relative;
  z-index: 0;

  & > .sectionSolutionCardsSlider {
    position: relative;
    z-index: 1;
    max-width: 100%;

    @media (--viewport-lg) {
      margin-top: -225px;
      min-height: 225px;
    }
  }

  & > :last-child {
    margin-top: var(--size-54);
  }
}

.sectionHero {
  overflow: hidden;
}

.sectionSolutionCardsSlider {
  padding-block: var(--size-54);

  @media (--viewport-lg) {
    padding-top: 0;
    padding-bottom: var(--size-74);
  }

  & > * {
    overflow: hidden;
  }
}

.sectionPaddingLayout {
  padding-block: var(--size-54);

  @media (--viewport-md) {
    padding-block: var(--size-74);
  }
}

.sectionCallToAction {
  padding-block: var(--size-54);

  @media (--viewport-md) {
    padding-block: var(--size-150);
  }
}
