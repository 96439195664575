.page {
  position: relative;
  z-index: 0;

  & > .inPageNavigation {
    z-index: 1;
  }

  & > * {
    max-width: 100%;
  }
}

.sectionPadding {
  padding-block: var(--size-54);

  @media (--viewport-md) {
    padding-block: var(--size-74);
  }
}
