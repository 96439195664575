.component {
  padding-block: var(--size-54);
  position: relative;
  z-index: 0;
  overflow: hidden;
  display: grid;
  grid-template-columns:
    minmax(var(--container-padding), 1fr)
    minmax(0, var(--size-container-max-width-lg))
    minmax(var(--container-padding), 1fr);

  @media (--viewport-lg) {
    grid-template-columns:
      minmax(var(--container-padding), 1fr)
      minmax(0, calc(var(--size-container-max-width-lg) / 2))
      minmax(0, calc(var(--size-container-max-width-lg) / 2))
      minmax(var(--container-padding), 1fr);
  }

  & > * {
    min-width: 0;
  }

  & > .content {
    grid-column: 2;
    grid-row: 2;

    @media (--viewport-lg) {
      grid-row: 1;
      grid-column: 2;
      align-self: center;
    }
  }

  & > .image {
    grid-row: 1;
    grid-column: 2;
    align-self: center;

    @media (--viewport-lg) {
      grid-row: 1;
      grid-column: 3;
    }
  }
}

.content {
  padding-top: var(--size-42);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--size-24);
}

.image {
  overflow: hidden;
  border-radius: 50%;
  aspect-ratio: 1;

  & > .imageLayout {
    width: 100%;
    height: 100%;
  }
}

.buttons {
  & > * {
    margin-top: var(--size-6);
    margin-right: var(--size-12);
  }
}
