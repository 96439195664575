.component {
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--size-74);
  align-items: center;
  overflow: hidden;
  padding: var(--size-56) 0;

  @media (--viewport-lg) {
    grid-template-columns: 1fr 1fr;
    align-items: center;
  }

  & > .image {
    width: 100%;
    order: -1;
  }
}

.image {
  border-radius: 100%;
  overflow: hidden;
}

.textWrapperElement {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: var(--color-blue-700);

  & > .headingLayout {
    margin-bottom: var(--size-10);
  }

  & > .text {
    margin-bottom: var(--size-32);
  }
}

.text {
  font-size: var(--font-size-18);
}
