.component {
  & > .sectionContent {
    max-width: 100vw;
  }
}

.sectionContent {
  padding-block: var(--size-30);
  overflow: hidden;

  @media (--viewport-md) {
    padding-block: var(--size-42);
  }
}

.image {
  display: flex;
  justify-content: center;
}
