.component {
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--size-42);

  @media (--viewport-lg) {
    grid-template-columns: 1fr 1fr;
    padding: var(--size-48) 0;
  }
}

.textContainer {
  display: flex;
  flex-direction: column;
  row-gap: var(--size-18);

  & > * {
    min-width: 0;
  }

  & > .buttonLayout {
    margin-top: var(--size-12);
    width: max-content;
  }
}
