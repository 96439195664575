.component {
  & > * {
    width: 100%;

    &:first-child {
      margin-bottom: var(--size-24);

      @media (--viewport-md) {
        margin-bottom: 0;
      }
    }
  }

  & > .header {
    @media (--viewport-md) {
      grid-area: header;
    }
  }

  & > .cluster {
    &:nth-child(2) {
      @media (--viewport-md) {
        grid-area: cluster1;
      }
    }

    &:nth-child(3) {
      @media (--viewport-md) {
        grid-area: cluster2;
      }
    }
  }

  @media (--viewport-md) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto 1fr;
    grid-template-areas:
      'header header'
      'cluster1 cluster2';
    gap: var(--size-24) var(--size-24);
  }
}
