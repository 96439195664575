.component {
  & > .heading {
    @media (--viewport-lg) {
      max-width: calc(50% - var(--size-12));
    }
  }

  & > .grid {
    margin-top: var(--size-24);
  }
}

.grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--size-24);

  & > :nth-child(even) {
    @media (--viewport-lg) {
      transform: translateY(calc(var(--size-60) * -1));
    }
  }

  @media (--viewport-md) {
    grid-template-columns: repeat(2, minmax(200px, 1fr));
  }
}
