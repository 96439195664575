.component {
  --component-bg-color: transparent;
  --component-color: var(--color-blue-700);

  &.isActive {
    --component-bg-color: var(--color-blue-700);
    --component-color: var(--color-white);
  }

  color: var(--component-color);
  background: var(--component-bg-color);
  border-radius: var(--border-radius-20);
  transition:
    color var(--duration-xs) ease-in-out,
    background-color var(--duration-sm) ease-out;
  display: grid;
  grid-template-areas:
    'button'
    'section';

  & > .button {
    grid-area: button;
  }

  & > .section {
    grid-area: section;
  }
}

.button {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  text-align: left;
  font-weight: var(--font-weight-semibold);
  font-family: var(--font-family-base);
  font-size: var(--font-size-22);
  padding: var(--size-10) var(--size-10) var(--size-10) var(--size-18);

  @media (--viewport-md) {
    padding: var(--size-18) var(--size-32);
    gap: var(--size-24);
  }

  & > .icon {
    width: var(--size-32);
    height: var(--size-32);
  }
}

.titleElement {
  line-height: var(--line-height-heading);
  font-size: var(--font-size-22);
  font-weight: var(--font-weight-semi-bold);
}

.icon {
  --icon-bg-color: var(--color-blue-500);
  --icon-color: var(--color-white);

  &.isActive {
    --icon-bg-color: var(--color-white);
    --icon-color: var(--color-blue-700);
  }

  display: grid;
  place-content: center;
  color: var(--icon-color);
  background: var(--icon-bg-color);
  border-radius: 50%;
  aspect-ratio: 1;
  transition:
    color var(--duration-xs) ease-in-out,
    background-color var(--duration-xs) ease-in-out;

  & > * {
    display: flex;
    transform-origin: var(--size-4) var(--size-4);
    transform: translateY(calc(0.2 * (0.8 * 16px) * 0.5));
  }
}

.section {
  overflow: hidden;
  padding: 0 var(--size-10) 0 var(--size-20);

  @media (--viewport-md) {
    padding: 0 var(--size-24) 0 var(--size-32);
  }
}

.sectionInner {
  display: flex;
  flex-direction: column;
  font-size: var(--font-size-14);
  padding: var(--size-10) var(--size-10) var(--size-10) var(--size-18);

  @media (--viewport-md) {
    padding: 0;
  }

  & > *:not(:last-child) {
    margin-bottom: var(--size-10);
  }
}

.componentStandalone {
  border-radius: var(--border-radius-8);
}
