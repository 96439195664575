.component {
  & > .headingLayout {
    margin-bottom: var(--size-24);
  }
}

.items {
  display: flex;
  flex-direction: column;
  gap: var(--size-18);
}
