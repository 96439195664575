.component {
  & > .headingLayout {
    margin-bottom: var(--size-24);
  }
}

.items {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: var(--size-18);

  & > .item {
    min-height: 300px;

    &.itemWide {
      @media (--viewport-lg) {
        grid-column: span 2;
      }
    }
  }
}

.item {
  & > .cardLayout {
    height: 100%;
  }
}
