.component {
  display: flex;
  flex-direction: column;
  gap: var(--size-12);
}

.componentFilter {
  overflow: hidden;
}

.arrow {
  fill: var(--color-blue-100);
}

.listButtonElement {
  cursor: pointer;
  user-select: none;
  display: grid;
  gap: var(--size-8);
  align-items: center;
  grid-template-columns: auto 1fr;
  padding: var(--size-6);
  font-size: var(--font-size-14);

  &:focus {
    outline: none;
    border-radius: var(--border-radius-6);
    background-color: var(--color-background);
  }

  & > .checkboxElement {
    width: var(--size-18);
    height: var(--size-18);
  }
}

.label {
  white-space: nowrap;
}

.checkboxElement {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-white);
  border-radius: var(--border-radius-4);
  color: transparent;

  &.isChecked {
    color: var(--color-white);
    background-color: var(--color-blue-500);
  }

  & > .checkmarkLayout {
    width: 14px;
  }
}

.componentCollapsible {
  background-color: var(--color-blue-100);
  border-bottom: 1px solid var(--color-gray-100);
  overflow: hidden;
  border-radius: var(--border-radius-12);

  & > * {
    width: 100%;
  }
}

.title {
  padding: var(--size-24);
  font-size: var(--font-size-18);
  display: flex;
  font-weight: var(--font-weight-bold);
  line-height: var(--line-height-narrow);
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  text-align: left;

  & > .toggle {
    margin-left: var(--size-12);
    flex-shrink: 0;
    height: fit-content;
  }
}

.toggle {
  display: flex;
  align-items: flex-start;
  color: var(--color-blue-500);
  transition: transform var(--duration-sm) var(--ease-in-out);

  &.isOpen {
    transform: rotate(180deg);
  }
}

.content {
  --padding: var(--size-24);

  overflow: hidden;
  text-align: left;
  transition: padding var(--duration-xs);

  &:not(.isOpen) {
    padding-bottom: 0;
  }
}

.text {
  padding: 0 var(--padding) var(--padding);
  font-size: var(--font-size-16);

  & > :not(:first-child) {
    margin-top: var(--size-24);
  }
}
