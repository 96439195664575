.component {
  position: relative;
  z-index: 0;

  & > .arrowNavigation {
    @media (--viewport-md) {
      position: absolute;
      z-index: 1;
      top: 50%;
      left: calc((var(--size-18) + var(--size-54)) * -1);
    }
  }
}

.slider {
  display: flex;
  clip-path: inset(-100% -100% -100% 0);

  &:global(.keen-slider) {
    overflow: visible !important;
  }
}

.navigation {
  display: flex;
  justify-content: space-between;
  padding: var(--size-24) var(--size-12);
}

.dots {
  display: flex;
  align-items: center;

  & > * {
    margin: 0 var(--size-6);
    width: var(--size-12);
    height: var(--size-12);
  }
}

.dot {
  padding: var(--size-6);
  background-color: var(--color-blue-300);
  border: none;
  border-radius: 50%;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &.isActive {
    background-color: var(--color-blue-700);
  }
}

.pagination {
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-24);
  line-height: var(--line-height-narrow);
  color: var(--color-blue-700);
}

.arrowNavigation {
  display: none;

  @media (--viewport-lg) {
    display: flex;
    flex-direction: column;
    transform: translateY(-50%);
  }

  & > :not(:last-child) {
    margin-right: var(--size-12);

    @media (--viewport-md) {
      margin-bottom: var(--size-12);
    }
  }

  & > * {
    width: var(--size-54);
    height: var(--size-48);
  }
}

.arrow {
  opacity: 0.5;
  color: var(--color-white);
  background-color: var(--color-blue-700);
  transition: opacity var(--duration-sm) var(--ease-in-out);

  &.isActive {
    opacity: 1;
  }
}
