.component {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--size-32);

  & > .buttonIconLayout {
    width: 2.6rem;
    height: 2.6rem;
  }
}

.bullets {
  display: flex;
  align-items: center;
  gap: var(--size-18);
}

.hellip {
  display: flex;
  align-items: center;
  justify-content: center;
}

.componentBullet {
  color: var(--color-blue-700);
  font-size: var(--font-size-14);
  padding: var(--size-10);
  border-radius: 50%;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color var(--duration-sm) linear;

  &.active,
  &:focus,
  &:hover {
    background-color: var(--color-blue-100);
  }
}

.item {
  & > .bulletLayout {
    width: 2.4rem;
    height: 2.4rem;
  }
}

.componentButtonIcon {
  padding: var(--size-6);
  background-color: var(--color-blue-700);
  border-radius: 50%;
  color: var(--color-white);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: var(--font-size-14);
  transition: background-color var(--duration-sm) linear,
    color var(--duration-sm) linear;

    &:disabled {
      display: none;
    }

  &.active,
  &:focus,
  &:hover {
    background-color: var(--color-blue-500);
    color: var(--color-white);
  }
}
