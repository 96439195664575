.page {
  & > * {
    max-width: 100vw;
  }
}

.sectionPadding {
  padding-block: var(--size-54);

  @media (--viewport-md) {
    padding-block: var(--size-74);
  }
}
