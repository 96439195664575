.component {
  display: inline-flex;
  color: var(--color-white);
  background-color: var(--color-blue-700);
  font-size: var(--font-size-14);
  border-radius: var(--border-radius-50);
  padding: var(--size-8) var(--size-12);
  line-height: 1;

  &.isLight {
    color: var(--color-blue-700);
    background-color: var(--color-blue-200);
  }
}
