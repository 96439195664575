.component {
  background-color: #fff;
  padding: 20px;
  border-radius: 20px;

  &> :not(:last-child) {
    margin-bottom: 20px;
  }
}

.body {
  & > :not(:last-child) {
    margin-bottom: 18px;
  }
}

.title {
  font-family: 'Graphik', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 22px;
  color: #03031a;
}

.description {
  font-size: 16px;
}

.componentPermission {
  background-color: #F0F6FF;
  border-radius: 10px;
  padding: 10px;
}

.input {
  display: flex;
  align-items: center;
  padding-bottom: 1rem;

  & > .permissionTitle {
    margin-left: 1rem;
  }
}

.permissionTitle {
  font-family: 'Graphik', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 18px;
  color: #03031a;
}

.componentPermission.isDisabled {
  color: #828282;
}

.label {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 30px;

  & > .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.label .checkbox {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  background-color: #cccccc;
  border-radius: 30px;

  &:before {
    position: absolute;
    content: "";
    height: 22px;
    width: 22px;
    left: 4px;
    bottom: 4px;
    background-color: #fff;
    border-radius: 50%;
  }
}

.checkbox:checked + .slider {
  background-color: #0000ff;
}

.checkbox:checked + .slider:before {
  transform: translateX(22px);
}
