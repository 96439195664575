.page {
  padding-block: var(--size-74);
}

.sectionHeading {
  padding-bottom: var(--size-54);
}

.sectionGrid {
  padding-bottom: var(--size-54);
}

.componentArticleGrid {
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--size-24);

  @media (--viewport-md) {
    grid-template-columns: repeat(2, minmax(200px, 1fr));
  }
}
